.custom-button-bar {
  display: flex;
  align-items: center;
	gap: 10px;
}

// .active {
//   background-color: #eeeeee;
// }

.more-button-container {
  position: relative;
}