body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background-color: #ededed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hide scrolling bar */

/* For Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* For Internet Explorer, Edge */
body {
  -ms-overflow-style: none;
}

/* For Firefox */
body {
  scrollbar-width: none;
}

.App {
  max-width: 600px;
  margin: 20px auto;
	padding: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}



// HEMLOCK MINI
.hm-title {
	padding: 0;
	margin: 0;
}

.hm-keyword-shadow {
	box-shadow: 0 5px 25px rgba(0, 0, 0, 0.071);
}

.hm-ai-gradient {
	background-image: linear-gradient(to bottom right, #5E6EFF, #FF4747);
}

.hm-ai-gradient-font {
	background: linear-gradient(to bottom right, #5E6EFF, #FF4747);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.hm-input-keywords-container {
	@extend .custom-rounded-corner-0;
	background-color: #ffffffbb;
	display: flex;
	flex-direction: column;
	position: relative;
	height: auto;
	width: 100%;
	box-sizing: border-box; /* Ensures the width includes padding and border */
	justify-content: center;
	align-items: center;
	box-shadow: 0 20px 80px rgba(127, 0, 196, 0.218);
}

.hm-input-keywords-container:after {
	// @extend .custom-rounded-corner-0;
	@extend .hm-ai-gradient;
	content: '';
	border-radius: 22px;
	position: absolute;
	inset: 0;
	margin: -0.1rem;
	// width: 100%;
	// height: 100%;
	// border: 3px solid transparent;
	z-index: -1;
}

.hm-input-keywords-container-inner {
	@extend .custom-rounded-corner-0;
	display: flex;
	flex-direction: column;
	position: relative;
	height: auto;
	// overflow: hidden;
	width: 100%;
}


.hm-input-containers {
	padding: 30px;
	box-sizing: border-box;
	position: relative;
	// background-color: #ffffff;
	height: auto;
	width: 100%;
	align-items: baseline;
	justify-content: baseline;
}

.hm-input-containers:before {
	@extend .hm-ai-gradient;
	content: '';
	margin-left:-30px;
	box-sizing: border-box;
	position: absolute;
	height: 1.5px;
	width: 100%;
	bottom: 0px;
}

.hm-input-ui {
	@extend .custom-font-ui-0;
	font-family: inherit; 
	font-size: inherit;
	width: 100%;
	border: none;
	background: none;
	outline: none;
	padding: 0;
	margin: 0;
	color: black;
	resize: none;
  overflow: hidden;
	height: 0px;
}

.hm-input-ui::placeholder {
  color: rgba(0, 0, 0, 0.47);
  // opacity: 0.7;
}

.hm-keywords-containers {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	width: 100%;

	&-content {
		// @extend .truncate-single-line;
		padding: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		position: relative;
		box-sizing: border-box;
		max-width: 100%;
	}

	&-banner {
		// @extend .truncate-single-line;
		display: flex;
		flex-wrap: nowrap;
		padding: 10px;
		flex-direction: row;
		position: relative;
		overflow: hidden;
		justify-content: space-between;
		
	}

	&-content::before {
		@extend .hm-ai-gradient;
		content: '';
		margin-left:-20px;
		box-sizing: border-box;
		position: absolute;
		height: 1.5px;
		width: 100%;
		bottom: 0px;
	}
}
 



.field-display, .tool-display {
	@extend .hm-ai-gradient-font;
	@extend .custom-font-ui-2;
	@extend .truncate-single-line;
  // font-size: 14px;
  // color: #555;
	max-width: 60%;

}

.keywords-container {
  display: flex;
  flex-wrap: nowrap;
	flex-direction: column;
  gap: 0px;
	overflow: hidden;
}
.keywords-container-content {
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;;
	width: 100%;
  gap: 15px;
}

.keywords-container .not-selected {
	@extend .truncate-single-line;
  @extend .custom-button-tiny-sub;
	max-width: 100%;

	span {
		@extend .hm-ai-gradient-font;
	}
}

.keywords-container .selected {
	@extend .truncate-single-line;
	@extend .custom-font-ui-2;
	@extend .custom-clickable-small;
	// @extend .custom-button-select-color;
	max-width: 100%;
  border-radius: 20px;
  height: 40px;
	display: flex; 
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 1px solid #00000015;
  cursor: pointer;
	
	background-color: #ffffff;
	
	span {
		@extend .hm-ai-gradient-font;
	}

	&:hover {
		background-color: #ffffffaf;
	}
}

.generate-link {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.generate-link:hover {
  background-color: #218838;
}

/* APP TEMPLATE */

.content {
	@extend .custom-color-ui-light-3;

	display: flex;
  flex-direction: column;
	height: 100vh;
  // height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
	scrollbar-gutter: stable;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.content-display {
	overflow-y: auto;
	flex-grow: 1;
	// height: 100vh;
}

.page-padding {
	padding: 20px;
}


// FONT
.custom-font-title-0 {
	font-weight: 800;
  font-size: 40px;
}

.custom-font-title-1 {
	font-weight: 800;
  font-size: 32px;
}

.custom-font-title-2 {
	font-weight: 800;
  font-size: 28px;
}

.custom-font-title-3 {
	font-weight: 800;
  font-size: 24px;
}

.custom-font-ui-0 {
	font-size: 24px;
  font-weight: 600;
}

.custom-font-ui-1 {
	font-size: 20px;
  font-weight: 600;
}


.custom-font-ui-2 {
	font-size: 16px;
  font-weight: 600;
}

.cutom-font-note {
	font-size: 14px;
  font-weight: 400;
	color: rgb(111, 111, 111);
}

//COLORS

.custom-color-ui-light-0 {
	background-color: #FFFFFF;
}

.custom-color-ui-light-1 {
	background-color: #f8f8f8;
}

.custom-color-ui-light-2 {
	background-color: #efefef;
}

.custom-color-ui-light-3 {
	background-color: #e0e0e0;
}

.custom-color-ui-verified {
	background-color: #000000;
}

//BACKGROUND

.custom-background-blur-light {
	background-color: #ffffff7c;
	backdrop-filter: blur(200px);
	-webkit-backdrop-filter: blur(200px);
}


//SHAPES

.custom-rounded-corner-0 {
	border-radius: 20px;
}

.custom-rounded-corner-2 {
	border-radius: 10px;
}

//COMPOSITION

.custom-margin-back {
	margin-bottom: 40px;
}

.custom-margin-major {
	margin-bottom: 20px;
}

.custom-vertical-layout {
	display: flex;
	flex-direction: column;
	gap: 40px;
}


//DIVS

.custom-container-round-back {
	@extend .custom-rounded-corner-0;
	padding: 30px;
	display: flex;
	flex-direction: column;
	position: relative;

	width: 100%;
	box-sizing: border-box; /* Ensures the width includes padding and border */
	height: auto; /* Height will adjust based on the content */
}

.custom-container-round-back-mini {
	@extend .custom-rounded-corner-0;
	display: flex;
	padding: 20px;
	flex-direction: column;
	position: relative;

	gap: 10px;

	width: 100%;
	box-sizing: border-box; /* Ensures the width includes padding and border */
	height: auto; /* Height will adjust based on the content */
}

.hover-div:hover {
  cursor: pointer;
}

.custom-div-band {
	width: 100%;
	padding: 12px 20px;

	span {
			@extend .custom-font-ui-2;
			color: white;
	}

	/* Example usage

	<div className="custom-div-band custom-color-ui-verified">
		<span>Recommended By</span>
	</div>

	*/

}



// AVATAR

.avatar-round {
	vertical-align: middle;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #0000003e;
}

// ICONS

.icon-small {
	width: 25px; /* You can adjust the width as needed */
  height: 25px; /* Fixed height */
	border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

	img {
		max-width: 100%; /* Adjust the icon size */
		max-height: 100%; /* Make sure it fits within the button */
	}
}

.icon-white {
	filter: invert(1);
}

.icon-text-div {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

// BUTTONS

.custom-clickable {
  // display: inline-block;
  cursor: pointer;
	z-index: inherit;
  // position: relative;
}

				.custom-clickable-tiny {
					@extend .custom-clickable;
				}

				.custom-clickable-tiny:hover {
					transition: transform 0.2s ease-in-out;
					transform: scale(1.05); /* Slightly enlarge based on size */
				}

				.custom-clickable-tiny:active {
					transition: transform 0.12s ease-in-out;
					transform: scale(0.95); /* Slightly smaller based on size */
				}

				.custom-clickable-small {
					@extend .custom-clickable;
				}

				.custom-clickable-small:hover {
					transition: transform 0.2s ease-in-out;
					transform: scale(1.01); /* Slightly enlarge based on size */
				}

				.custom-clickable-small:active {
					transition: transform 0.12s ease-in-out;
					transform: scale(0.98); /* Slightly smaller based on size */
				}

				.custom-clickable-median {
					@extend .custom-clickable;
				}

				.custom-clickable-median:hover {
					transition: transform 0.2s ease-in-out;
					transform: scale(1.003); /* Slightly enlarge based on size */
				}

				.custom-clickable-median:active {
					transition: transform 0.12s ease-in-out;
					transform: scale(0.995); /* Slightly smaller based on size */
				}

				.custom-clickable-large {
					@extend .custom-clickable;
				}

				.custom-clickable-large:hover {
					transition: transform 0.2s ease-in-out;
					transform: scale(1.002); /* Slightly enlarge based on size */
				}

				.custom-clickable-large:active {
					transition: transform 0.12s ease-in-out;
					transform: scale(0.995); /* Slightly smaller based on size */
				}



.custom-icon-button {
	@extend .custom-clickable-tiny;
  width: 50px; /* You can adjust the width as needed */
  height: 50px; /* Fixed height */
	border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.custom-icon-button img {
  max-width: 60%; /* Adjust the icon size */
  max-height: 60%; /* Make sure it fits within the button */
}

.custom-icon-button:hover {
  background-color: #00000013; /* Change background color on hover */
}

.custom-button-main-color {
	background-color: #ffffff;
	color: rgb(0, 0, 0);
	border: none;
	border: 1px solid #00000015;
	
	transition: background-color 0.12s ease-in-out;
	z-index: 100;

	// img {
	// 	filter: invert(1);
	// }
}

.custom-button-main-color:hover {
	// background-color: #ffffffb3;
	box-shadow: 0 5px 25px rgba(0, 0, 0, 0.071);

}

.custom-button-select-color {
	background-color: #512c89;
	color: white;
	border: 1px solid #00000015;
	
	transition: background-color 0.12s ease-in-out;

	img {
		filter: invert(1);
	}
}

.custom-button-select-color:hover {
	background-color: #68449e;
}

.custom-button-sub-color {
	background-color: rgba(0, 0, 0, 0.086);
	border: 1px solid #ffffff4e;
	// backdrop-filter: blur(200px);
	// -webkit-backdrop-filter: blur(200px);
	color: black;
	// border: none;
	
	
	transition: background-color 0.12s ease-in-out;
}

.custom-button-sub-color:hover {
	background-color: rgba(0, 0, 0, 0.186);
}

.custom-button-icon {
	width: 25px;
  height: 25px;
}

.custom-wide-button {
	@extend .custom-font-ui-1;
	@extend .custom-clickable-small;
	@extend .truncate-single-line;

	border-radius: 25px;

  height: 50px;
	display: flex; 
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	gap: 8px;
}

.custom-wide-button span {
	@extend .truncate-single-line;
}

.custom-round-button {
	@extend .custom-button-sub-color;
	@extend .custom-clickable-tiny;
	display: flex; 
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	border-radius: 50%;
	padding: 0;
	width: 50px;
	height: 50px;
}

.custom-wide-button-main {
	@extend .custom-wide-button;
	@extend .custom-button-main-color;
}

.custom-wide-button-sub {
	@extend .custom-wide-button;
	@extend .custom-button-sub-color;
}

.custom-wide-button-main-group {
	@extend .custom-wide-button-main;
	flex: 1;
}

.custom-wide-button-sub-group {
	@extend .custom-wide-button-sub;
	flex: 1;
}


.custom-button-tiny-sub {
	@extend .custom-font-ui-2;
	@extend .custom-clickable-small;
	@extend .custom-button-sub-color;

  border-radius: 20px;

  height: 40px;
	display: flex; 
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
	justify-content: center;
	text-align: center;
	// border: none;
  cursor: pointer;
}

.custom-button-tiny-selected {
	@extend .custom-font-ui-2;
	@extend .custom-clickable-small;
	@extend .custom-button-select-color;

  border-radius: 20px;

  height: 40px;
	display: flex; 
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: none;
  cursor: pointer;
}


.custom-button-main:active {
	background-color: #000000;
	transition: background-color 0.0s ease-in-out;
}

.custom-button-group-hori-wide {
	display: flex;
	flex-direction: row;
	gap: 10px;
}


// DROPDOWN

@keyframes appearAnimation {
  0% {
    transform: scale(0) translateY(0%);
    opacity: 0;
  }
  // 90% {
  //   transform: scale(1.05) translateY(0%);
  //   opacity: 0.3;
  // }
  100% {
    transform: scale(1) translateY(0%);
		opacity: 1;
  }
}

@keyframes disappearAnimation {
  0% {
    transform: scale(1) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(0) translateY(0%);
    opacity: 0;
  }
}

.dropdown-menu-up.show {
  animation: appearAnimation 0.3s forwards;
}

.dropdown-menu-up.hide {
  animation: disappearAnimation 0.3s forwards;
}



.dropdown-menu {
	@extend .custom-rounded-corner-0;
  position: absolute;
	width: 300px;
  right: 0;
  top: 60px;
	background-color: rgba(0, 0, 0, 0.086);
	backdrop-filter: blur(200px);
	-webkit-backdrop-filter: blur(200px);
  // background-color: rgb(214, 214, 214);
  // border: 2px solid rgba(255, 255, 255, 0.512);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.158);
  z-index: 1000;
	overflow: hidden;

  transition: all 0.3s ease-in-out;
  transform-origin: top;

  &-up {
		@extend .custom-rounded-corner-0;
		position: absolute;
		width: 300px;
		right: 0;
		background-color: rgba(0, 0, 0, 0.086);
		backdrop-filter: blur(200px);
		-webkit-backdrop-filter: blur(200px);
		// background-color: rgb(214, 214, 214);
		// border: 2px solid rgba(255, 255, 255, 0.512);
		box-shadow: 0 20px 60px rgba(0, 0, 0, 0.158);
		z-index: 1000;
		overflow: hidden;

		transition: all 0.3s ease-in-out;
		bottom: 60px;
    transform-origin: right bottom;
  }
}


.dropdown-item {
	@extend .custom-clickable-small;
  width: 100%;
	height: 50px;
	// border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
	gap: 10px;
}

.dropdown-item:hover {
	background-color: #00000013;
}

.dropdown-item .dropdown-text {
  margin-left: 8px;
}


// INPUTS

.custom-input-ui {
	@extend .custom-font-ui-1;
	@extend .custom-rounded-corner-2;
	@extend .custom-clickable-small;
	@extend .custom-color-ui-light-1;

	width: 100%;
  padding: 15px 15px 15px 15px;
  box-sizing: border-box;
	outline: 2px solid rgba(0, 0, 0, 0.086);
	border: none;
	cursor: text;
	color: black;
}

.custom-input-ui:hover {
	background-color: #ffffff;
}

.custom-input-ui:focus {
	background-color: #ffffff;
	outline: 2px solid #000000;
	border: none;
}

// TEXT TRUNCATION

.truncate-single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-multi-line {
	display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines before truncating */
  overflow: hidden;
  text-overflow: ellipsis;
}

// POSITIONING HELPERS

.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spread-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

//HEADER SLIDER

.tab-slider {
  background-color: #00000010;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  padding: 5px;
  width: 300px;
  margin: auto;
}

.tabs {
  display: flex;
  position: relative;
  width: 100%;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  z-index: 1;
	font-weight: bold;
}


.slider {
	background-color: #ffffff;
	// border: 1px solid #00000034;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 33.33%;
  border-radius: 50px;
  transition: transform 0.3s ease;
  z-index: 0;
}